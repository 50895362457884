import moment from "moment";

const timeFormat = function (time, format = 'YYYY-MM-DD') {
  moment.locale('zh-cn')
  let date = moment(time)
  if (!date.isValid()) {
    console.log('时间不合法， 请检查入参：时间')
    return '--'
  }
  return date.format(format)
}
const percentFormat = function (num) {
  if (isNaN(num)) {
    return '--'
  } else {
    num = (num * 100).toFixed(0) + '%'
    return num
  }
}
export default {
  timeFormat,
  percentFormat
}