export default [
  {
    path: "/",
    name: "home",
    component: () => import("../view/home"),
    meta: {
      title: '首页'
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../view/login"),
    meta: {
      title: '登录'
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../view/register"),
    meta: {
      title: '注册'
    }
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../view/forget"),
    meta: {
      title: '重置密码'
    }
  },
  {
    path: "/resetPwd",
    name: "resetPwd",
    component: () => import("../view/resetPwd"),
    meta: {
      title: '重置密码'
    }
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../view/users"),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: "/surveyDetail",
    name: "surveyDetail",
    component: () => import("../view/surveyDetail"),
    meta: {
      title: '问卷'
    }
  },
  {
    path: "/extSurveyDetail",
    name: "extSurveyDetail",
    component: () => import("../view/extSurveyDetail"),
    meta: {
      title: '问卷'
    }
  },
  {
    path: "/extResult",
    name: "extResult",
    component: () => import("../view/extResult"),
    meta: {
      title: '结果信息'
    }
  },
  {
    path: "/surveyHistory",
    name: "surveyHistory",
    component: () => import("../view/surveyHistory"),
    meta: {
      title: '我的作答'
    }
  },
  {
    path: "/coin",
    name: "coin",
    component: () => import("../view/coin"),
    meta: {
      title: '余额明细'
    }
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../view/withdraw"),
    meta: {
      title: '提现'
    }
  },
  {
    path: "/prizeHistory",
    name: "prizeHistory",
    component: () => import("../view/prizeHistory"),
    meta: {
      title: '提现记录'
    }
  },
  {
    path: "/needPicList",
    name: "needPicList",
    component: () => import("../view/needPicList"),
    meta: {
      title: '待上传凭证列表'
    }
  },
  {
    path: "/rule",
    name: "rule",
    component: () => import("../view/rule"),
    meta: {
      title: '问卷规则'
    }
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../view/account"),
    meta: {
      title: '账户设置'
    }
  },
  {
    path: "/editMobile",
    name: "editMobile",
    component: () => import("../view/editMobile"),
    meta: {
      title: '手机号'
    }
  },
  {
    path: "/editNickname",
    name: "editNickname",
    component: () => import("../view/editNickname"),
    meta: {
      title: '昵称'
    }
  },
  {
    path: "/editEmail",
    name: "editEmail",
    component: () => import("../view/editEmail"),
    meta: {
      title: '邮箱'
    }
  },
  {
    path: "/editAlipay",
    name: "editAlipay",
    component: () => import("../view/editAlipay"),
    meta: {
      title: '支付宝账号'
    }
  },
  {
    path: "/editName",
    name: "editName",
    component: () => import("../view/editName"),
    meta: {
      title: '姓名'
    }
  },
  {
    path: "/setPassWord",
    name: "setPassWord",
    component: () => import("../view/setPassWord"),
    meta: {
      title: '设置密码'
    }
  },
  {
    path: "/attribute",
    name: "attribute",
    component: () => import("../view/attribute"),
    meta: {
      title: '个人信息'
    }
  },
  {
    path: "/uploadPicture",
    name: "uploadPicture",
    component: () => import("../view/uploadPicture"),
    meta: {
      title: '完成答题'
    }
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("../view/promotion"),
    meta: {
      title: '推广码'
    }
  },
  {
    path: "/invitationInfo",
    name: "invitationInfo",
    component: () => import("../view/invitationInfo"),
    meta: {
      title: '我的朋友'
    }
  },
  {
    path: "/inviteRule",
    name: "inviteRule",
    component: () => import("../view/inviteRule"),
    meta: {
      title: '邀请规则'
    }
  },
];
