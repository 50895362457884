import Vue from "vue";
import Router from "vue-router";
import routes from "./routers";

Vue.use(Router);
const router = new Router({
  routes,
  mode: "history",
});
router.beforeEach( (to, from, next) => {
  document.title = to.meta.title
  document.querySelector('title').innerText = to.meta.title
  console.log('from:', from)
  next()
})

export default router;
