import Vue from "vue";
import WeuiVue from "weui-design-vue";
// import "weui";
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import "weui-design-vue/lib/weui-design-vue.css";
import filters from "./filters";
import App from "./App.vue";
import router from "./router";

Object.keys(filters).forEach(i => Vue.filter(i, filters[i]))

Vue.use(WeuiVue);
Vue.config.productionTip = false;

if (window.location.href.indexOf('debug=debug') > -1) {
  const eruda = require('eruda')
  eruda.init()
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
