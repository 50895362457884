<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("./assets/css/style.css");
@import url("./assets/css/common.css");
body {
  background-color: #f3f3f3;
}
i.icon {
  display: inline-block;
  vertical-align: middle;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  font-style: normal;
  position: relative;
}
.weui-cell__hd img {
  display: block;
  margin-right: 5px;
}
.weui-cell__hd img {
  margin-right: 0.64rem;
}
</style>
